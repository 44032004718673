<template>
    <div>
        <b-container>
            <b-row
                cols="5"
                align-content="between"
            >
                <b-col cols="12" md="9">
                    <h3>Activity Details</h3>
                </b-col>
                <b-col cols="12" md="3">
                    <admin-device-menu></admin-device-menu>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>{{devCustomer}} - {{devId}}</p>
                </b-col>
            </b-row>
        </b-container>
        <b-card style="min-width: 44rem">
            <b-container>
                <b-row cols="20">
                    <b-col cols="4">
                        <date-picker
                            ref="calendar"
                            :attributes="attrs"
                            mode="date"
                            :max-date="getMaxDate"
                            v-model="date"
                            @dayclick="onDayClick"
                            @update:page="onChange"
                            @update:from-page="onChange"
                        ></date-picker>
                    </b-col>
                    <b-col cols="8">
                        <b-card
                            class="text-center mb-2"
                            style="max-width: 50rem; min-width: 18rem"
                            header-tag="header"
                        >
                            <template #header>
                                <h6>{{lastMistActivity}}</h6>
                                <b-container>
                                    <b-row cols="20">
                                        <b-col>
                                            <b-container>
                                                <b-row cols="10">
                                                    <b-col>
                                                        <b-icon-diagram3-fill></b-icon-diagram3-fill>
                                                        <div></div>
                                                        <small>{{lastZone}}</small>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                        <b-col>
                                            <b-container>
                                                <b-row cols="10">
                                                    <b-col>
                                                        <b-icon-clock-fill></b-icon-clock-fill>
                                                        <div></div>
                                                        <small>{{lastDur}}</small>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                        <b-col>
                                            <b-container>
                                                <b-row cols="10">
                                                    <b-col>
                                                        <b-icon-bar-chart-line-fill></b-icon-bar-chart-line-fill>
                                                        <div></div>
                                                        <small>{{lastFlow}}</small>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                        <b-col>
                                            <b-container>
                                                <b-row cols="10">
                                                    <b-col>
                                                        <b-icon-droplet-fill></b-icon-droplet-fill>
                                                        <div></div>
                                                        <small>{{lastGal}}</small>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                        <b-col>
                                            <b-container>
                                                <b-row cols="10">
                                                    <b-col>
                                                        <b-icon-bezier2></b-icon-bezier2>
                                                        <div></div>
                                                        <small>{{lastPulse}}</small>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </template>
                            <div class="chart-wrapper">
                                <chart :options="options" class="echarts" autoresize></chart>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-table striped hover responsive="true"
                                 no-border-collapse
                                 :items="items"
                                 :fields="fields"
                                 :busy="isBusy"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 select-mode="single"
                                 selectable
                                 ref="activityTable"
                                 style="overflow-y: hidden"
                                 @row-selected="onRowSelect"
                        >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
import eTypes from "@/enumTypes";
import store from "@/store";
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
    name: "AdminActivities",
    props: ['id'],
    components: {
      AdminDeviceMenu
    },
    data() {
        return {
            devId: null,
            items: [],
            fields: [
                'status',
                'date',
                'time',
                'initiator',
                'zone',
                'secs',
                'gal',
                {key: 'skip', label: 'Skip?'},
                {key: 'reason', label: 'Reason?'}
            ],
            date: new Date(),
            attrs: [
                {
                    key: 'today',
                    highlight: {
                        color: 'blue',
                        fillMode: 'light'
                    },
                    dates: new Date()
                }],
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            isBusy: true,
            selected: null,
            flowData: [],
            primeData: [],
            pulseData: [],
            timeSteps: [],
        }
    },
    created() {
        this.devId = this.$route.params.id;
        let startDate = new Date();
        let endDate = new Date();
        startDate.setHours(0,0,0,0);
        startDate.setDate(1);
        endDate.setHours(0,0,0,0);
        endDate.setDate(endDate.getDate()+1);
        store.dispatch('getDetailActivityLogOther', {
            devId: this.devId,
            userId: this.$store.getters.adminSelectedDev.userId,
            startDate: Math.round(startDate.getTime() / 1000),
            endDate: Math.round(endDate.getTime() / 1000)
        }).then(() => {
            this.calUpdate();
            this.tableUpdate();
            setTimeout(() => {
              if (this.items.length > 0) {
                this.$refs.activityTable.selectRow(0);
              }
            }, 1000);
        });
    },
    methods: {
        onRowSelect(items) {
            if (items.length > 0) {
                this.selected = items[0];
                if (this.selected.flow.length === 30 && this.selected.pulse.length === 30)
                    this.graphUpdate();

                if (this.selected.skip === "Y") {
                    this.selected.flow = [];
                    this.selected.pulse = [];
                    for (let i =0; i < 30; i++) {
                        this.selected.flow.push(0);
                        this.selected.pulse.push(0);
                    }
                    this.graphUpdate();
                }

            } else {
                this.selected = null;
            }
        },
        onDayClick() {
            this.clearGraph();
            this.tableUpdate();
            setTimeout(() => {
              if (this.items.length > 0) {
                this.$refs.activityTable.selectRow(0);
              }
            }, 1000);
        },
        onChange(page) {
            let startDate = new Date(page.year, page.month-1, 1, 0, 0, 0, 0);
            let endDate = new Date(page.year, page.month-1, 31, 23, 59, 59, 0);
            store.dispatch('getDetailActivityLogOther', {
                devId: this.devId,
                userId: this.$store.getters.adminSelectedDev.userId,
                startDate: Math.round(startDate.getTime() / 1000),
                endDate: Math.round(endDate.getTime() / 1000)
            }).then(() => {
                this.calUpdate();
                //this.items = [];
            });
        },
        clearGraph() {
            this.flowData = [];
            this.pulseData = [];
            this.primeData = [];
            this.timeSteps = [];
        },
        graphUpdate() {
            const flowTemp = this.selected.flow;
            this.flowData = [];
            this.primeData = [];
            const dur = this.selected.secs;
            this.timeSteps = [];
            const tickStep = dur / 30;
            let tickLabel = 0;
            for (let i = 0; i < 30; i++) {
                if (i < 6) {
                    this.primeData.push(flowTemp[i]);
                    this.flowData.push('-');
                } else if (i === 6) {
                    this.primeData.push(flowTemp[i]);
                    this.flowData.push(flowTemp[i]);
                } else {
                    this.flowData.push(flowTemp[i]);
                }
                if (i === 29)
                    tickLabel += tickStep;
                this.timeSteps.push(tickLabel.toFixed(1));
                tickLabel += tickStep;
            }
            this.pulseData = this.selected.pulse;
        },
        tableUpdate() {
            this.items = [];

            const actLog = this.$store.getters.activityDetailLog;
            for (let i = 0; i < actLog.length; i++) {
                let actItem = {};

                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG) {
                    actItem.status = "Clog";
                    actItem._cellVariants = { status: 'warning'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK) {
                    actItem.status = "Leak";
                    actItem._cellVariants = { status: 'danger'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW) {
                    actItem.status = "Low";
                    actItem._cellVariants = { status: 'light'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW) {
                    actItem.status = "No Flow";
                    actItem._cellVariants = { status: 'warning'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL) {
                    actItem.status = "Normal";
                    actItem._cellVariants = { status: 'success'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_REFILL) {
                    actItem.status = "Refill";
                    actItem._cellVariants = { status: 'primary'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_SKIPPED) {
                    actItem.status = "Skipped";
                    actItem._cellVariants = { status: 'info'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_TEST) {
                    actItem.status = "Test";
                    actItem._cellVariants = { status: 'secondary'};
                }
                actItem.flow = actLog[i].flow;
                actItem.pulse = actLog[i].pulse;
                actItem.secs = actLog[i].dur;
                actItem.timestamp = actLog[i].timestamp;

                if (actLog[i].skip)
                    actItem.skip = "Y";
                else
                    actItem.skip = "N";

                actItem.date = this.moment(actLog[i].timestamp * 1000).format("MM/DD/YYYY");
                const curDate = this.moment(this.date).format("MM/DD/YYYY");
                actItem.time = this.moment(actLog[i].timestamp * 1000).format("h:mm A");
                actItem.initiator = actLog[i].src;
                if (actLog[i].zone === 0)
                    actItem.zone = "0";
                else if (actLog[i].zone === 1)
                    actItem.zone = "1";
                else if (actLog[i].zone === 2)
                    actItem.zone = "2";
                else if (actLog[i].zone === 3)
                    actItem.zone = "1 & 2";
                actItem.secs = actLog[i].dur;
                actItem.gal = actLog[i].gal.toFixed(3);
                if (actLog[i].skip === true)
                    actItem.skip = "Y"
                else
                    actItem.skip = "N"
                actItem.reason = actLog[i].reason;

                if (actItem.date === curDate)
                    this.items.push(actItem);
            }
            this.isBusy = false;
            this.totalRows = this.items.length;
        },
        calUpdate() {
            // Update Calendar view
            this.attrs = [{
                key: 'today',
                highlight: {
                    color: 'blue',
                    fillMode: 'light'
                },
                dates: new Date()
            }];
            const actLog = this.$store.getters.activityDetailLog;
            for (let i = 0; i < actLog.length; i++) {
                let calItem = {};
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG) {
                    calItem.dot = 'orange';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK) {
                    calItem.dot = 'red';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW) {
                    calItem.dot = 'yellow';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW) {
                    calItem.dot = 'orange';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL) {
                    calItem.dot = 'green';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_REFILL) {
                    calItem.dot = 'blue';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_SKIPPED) {
                    calItem.dot = 'purple';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_TEST) {
                    calItem.dot = 'yellow';
                }

                const cYear = this.moment(actLog[i].timestamp * 1000).year();
                const cMonth = this.moment(actLog[i].timestamp * 1000).month();
                const cDay = this.moment(actLog[i].timestamp * 1000).date();
                calItem.dates = new Date(cYear, cMonth, cDay);
                this.attrs.push(calItem);
            }
        }
    },
    computed: {
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
                return "";
            else
                return this.$store.getters.deviceSys.sysName;
        },
        getMaxDate() {
            return new Date();
        },
        lastMistActivity() {
            if (this.selected) {
                let dateStr = this.moment(this.selected.timestamp * 1000).format("MMM D, YYYY");
                let timeStr = this.moment(this.selected.timestamp * 1000).format("h:mm A");
                return 'Selected Mist - ' + dateStr + ' @ ' + timeStr;
            }
            return "---"
        },
        lastZone() {
            if (this.selected) {
                return this.selected.zone;
            }
            return '';
        },
        lastDur() {
            if (this.selected) {
                return this.selected.secs + 's';
            }
            return '';
        },
        lastGal() {
            if (this.selected) {
                const fGal = parseFloat(this.selected.gal);
                return fGal.toFixed(2);
            }
            return '';
        },
        lastFlow() {
            if (this.selected) {
                let flowAvg = 0;

                for (let i = 0; i < this.selected.flow.length; i++) {
                    flowAvg += this.selected.flow[i];
                }
                flowAvg /= this.selected.flow.length;
                return flowAvg.toFixed(2);
            }
            return '';
        },
        lastPulse() {
            if (this.selected) {
                let pulseAvg = 0;
                for (let i = 0; i < this.selected.pulse.length; i++) {
                    pulseAvg += this.selected.pulse[i];
                }
                pulseAvg /= this.selected.pulse.length;
                return Math.round(pulseAvg * this.selected.secs);
            }
            return '';
        },
        options() {
            return {
                xAxis: {
                    type: 'category',
                    splitLine: {
                        show: true,
                        interval: 4
                    },
                    axisLabel: {
                        show: true,
                        interval: 4,
                        showMaxLabel: true
                    },
                    axisTick: {
                        show: true,
                        interval: 4,
                        length: 6
                    },
                    max: 29,
                    data: this.timeSteps
                },
                yAxis: {
                    type: "value"
                },
                legend: {
                    data: ['Prime','Rate', 'Pulses']
                },
                series: [
                    {
                        name: 'Prime',
                        type: 'line',
                        symbolSize: 2,
                        data: this.primeData,
                        color: ["#e72020"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#e72020C8"]
                                }, {
                                    offset: 1, color: ["#e7202020"]
                                }],
                                global: false // false by default
                            }
                        }
                    },
                    {
                        name: 'Rate',
                        type: 'line',
                        symbolSize: 2,
                        data: this.flowData,
                        color: ["#3990dd"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#3990ddc8"]
                                }, {
                                    offset: 1, color: ["#3990dd20"]
                                }],
                                global: false // false by default
                            }
                        }
                    },
                    {
                        name: 'Pulses',
                        type: 'line',
                        symbolSize: 2,
                        data: this.pulseData,
                        color: ["#dd7f37"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#dd7f37C8"]
                                }, {
                                    offset: 1, color: ["#dd7f3720"]
                                }],
                                global: false // false by default
                            }
                        }
                    }
                ],
            }
        }
    }
}
</script>

<style scoped>
html {
    scroll-behavior: smooth;
}
.chart-wrapper {
    width: 100%;
    height: 100%;
}
.echarts {
    width: 100%;
    height: 280px;
}
</style>
